<!-- 棋牌游戏 -->
<template>
  <div>
    <div class="mod-poker">
      <div class="mod-banner" style="height: 461.205px">
        <!--焦点图-->
        <!-- <div class="slide-wrap" id="slides">
          <div class="num"><span class="current"></span></div>
          <ul>
            <li style="display: block">
              <a
                ><img
                  style="max-width: none"
                  src="../../assets/images/in/i202203c189ae8f69e54618b5836b401bfa5617.jpg"
              /></a>
            </li>
          </ul>
        </div> -->
        <Banner />
      </div>
      <div class="chess-tab">
        <swiper :options="swiperOption">
          <swiper-slide
            class="item"
            v-for="(item, index) in newList"
            :key="index"
          >
            <div class="gameList">
              <div
                class="casino-menu"
                :class="{ current: curId === citem.id }"
                v-for="(citem, cindex) in item"
                :key="cindex"
                @click="tapGame(citem)"
              >
                {{ citem.name }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="games-list">
        <div class="games-item clearfix">
          <ul class="clearfix">
            <li v-for="item in showList" :key="item.id" @click="_getToken(item,1)">
              <img loading="lazy" v-lazy="systemImgHost + item.pictureUrl" />
              <div v-show="item.nameEn" class="game-name">
                {{ item.nameEn }}
              </div>
              <div class="game-name">
                {{ item.name || "--" }}
                <span class="game-platform-name">Pragmatic Play</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="poker-text page-text">
        <div class="layout" :class="{ 'layout-height': moreShow }">
          <h4>PLAY POKER ONLINE &amp; WIN BIG ON FUN88</h4>
          <p>
            Poker in India is played on social occasions and customary poker
            parties during Diwali, but you can now play the thrilling online
            poker games on <strong>Fun88</strong> anytime and anywhere! Online
            poker with friends, full up tables, live tournaments and a seamless
            Poker club experience is what you can expect when you play online
            poker with real money in India! Play poker card games with real
            people and win real cash on a variety of games, whether it is Texas
            Hold’em, Omaha, Razz or Super 10.
          </p>
          <h4>
            THE MOST SECURE POKER SITES IN INDIA-BEST POKER WINNING HANDS!
          </h4>
          <p>
            Fun88 makes it easy for you to join the immersive gameplay of online
            poker India in our online casino in any way that suits you – be it
            via your browser, or the mobile app made for Android and iOS. Not to
            miss, we offer safe and secure banking and 24-hour customer support
            via all modes of telephone, email and chat to ensure you enjoy
            playing poker easily.
          </p>
          <p>
            You can join IDN Poker – which has the largest network for Asian
            players or Fun88 Poker – our poker sequence tables to try your luck
            in our online casino. From creating private tables of poker online
            as you invite friends and family to play poker, or playing online
            casino games that are strategic and complex against real players,
            Fun88’s poker games offer a fair and reliable platform.
          </p>
          <h4>WIN REAL CASH MONEY WITH POKER CARD GAMES</h4>
          <p>
            Online Poker games are high skill games that test the numerical
            capabilities of poker players. On Fun88, we’ve catered to poker game
            guides and videos helping newcomers with rules of poker online or
            strategy guides to help poker enthusiasts gain a winning edge.
          </p>
          <p>
            High stake tournaments are another USP of our online poker action as
            Fun88 brings together big money to be won. Fun88 also hosted the
            World Series of Poker Online 2020 and will host another Poker
            Championship bringing you all the action live!
          </p>
          <p>
            Fun88 has emerged as the best Poker sites in India because of our
            constant technology updates, automated shuffle switches for game
            security, and game integrity which remain our big focus.
          </p>
          <p>
            Don’t wait! Hold the poker winning hands right now.
            <a href="/in/register/">JOIN US</a> NOW!
          </p>
          <p>Fun88 the only leading online gaming site ever!</p>
        </div>
        <div @click="moreChange" class="read-more">READ MORE</div>
      </div>
    </div>
  </div>
</template>

<script>
import { _getToken } from "../../core/comp/common";
import { mapGetters } from "vuex";
import { Message } from "element-ui";
import {
  _thirdList,
  _searchGameAction,
  _recommendGameByIDAction,
} from "../../core/comp/pages/games";
import Banner from "../../core/components/Banner.vue";

export default {
    components: {
        Banner
    },
  data() {
    return {
      curId: "",
      loading: false,
      swiperOption: {
        //swiper3
        // autoplay: 3000,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 30,
        // loop:true,
        prevButton: ".swiper-button-prev",
        nextButton: ".swiper-button-next",
        pagination: ".swiper-pagination",
        paginationType: "fraction",
      },
      searchText: "", // 当前搜索的内容
      searchList: [], // 搜索结果

      activeGame: "", // 当前激活的游戏平台ID
      activeTab: 1, // 当前激活的分类 1-全部  2-热门游戏
      newList: [], // 分割之后的新数组
      pages: {
        // 分页管理
        currPage: 1,
        pageSize: 9999,
      },
      moreShow: true,
    };
  },
  watch: {
    activeGame(val) {
      // 监听平台变化
      if (this.gameChessPages[val]) {
        // 当平台变化时发现该平台有缓存的数据 则直接展示
        this.pages = JSON.parse(JSON.stringify(this.gameChessPages[val]));
      }
    },
    lists (val) { // 监听二级分类数据变化，当变化时默认获取第一个元素的子游戏列表
        if (val && val[0]) {
            this.tapGame(val[0]) // 当前页刷新时的初始化
        }
    },
    $route: {
      handler: function (val) {
        if (val) {
          let id = val.query.id;
          if (id) {
            let obj = this.lists.find((item) => {
              return item.id == id;
            });
            this.tapGame(obj); // 初始化
          } else {
            this.tapGame(this.lists[0]); // 初始化
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.splitList();
  },
  computed: {
    showList() {
      // 展示的游戏列表
      if (this.activeGame) {
        // 全部游戏列表
        if (this.activeTab == 1)
          return this.gameChessLists[this.activeGame] || [];
        // 热门游戏列表
        if (this.activeTab == 2)
          return this.gameChessRecommen[this.activeGame] || [];
      }
      return this.searchList; // 搜索结果
    },
    listTotal() {
      // 列表总数
      if (this.gameChessPages[this.activeGame])
        return this.gameChessPages[this.activeGame].total;
      return 0;
    },
    lists() {
      // 二级导航
      return this.gameMenu.arr_qp || [];
    },
    ...mapGetters([
      "isLogin",
      "gameMenu", // 游戏分类
      "gameChessLists", // 三级游戏 全部列
      "gameChessPages", // 三级游戏 全部列表的页面状态
      "gameChessRecommen", // 三级游戏 热门列
      'systemImgHost',
    ]),
  },
  methods: {
    moreChange() {
      this.moreShow = !this.moreShow;
    },
    _getToken,
    // 数组切割 列表 每组几个
    splitList() {
      for (let i = 0; i < this.lists.length; i += 8) {
        this.newList.push(this.lists.slice(i, i + 8));
      }
    },
    // 搜索
    search() {
      if (!this.isLogin) return Message.warning(this.$t("errorMessage.text1"));
      if (this.searchText === "") return;
      this.curId = "";
      this.activeGame = "";
      this.activeTab = 1;
      console.log("---搜索", this.searchText);
      setTimeout(() => {
        this.searchList = [];
        _searchGameAction(this.searchText).then((res) => {
          if (res) this.searchList = res;
        });
      }, 0);
    },
    // 翻页
    pageChange() {
      this.activeTab = 1; // 锁定为全部游戏
      this.tapGame(this.lists.find((item) => item.id == this.activeGame));
    },
    // 点击游戏平台
    tapGame(item) {
      if (this.loading) return;
      this.curId = item.id;
      this.activeTab = 1;
      console.log("---点击了", item);
      this.activeGame = item.id;
      setTimeout(() => {
        // 如果发现当前缓存的是正要请求的该页数据，则不用发送请求，直接用缓存即可，可以减少大量请求
        if (
          this.gameChessPages[this.activeGame] &&
          this.gameChessPages[this.activeGame].currPage == this.pages.currPage
        )
          return;
        this.loading = true;
        _thirdList({ item, pages: this.pages }, "chess").then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
      }, 0);
    },
    // 获取推荐游戏
    getRecommend() {
      this.activeTab = 2; // 锁定为推荐游戏
      const item = this.lists.find((item) => item.id == this.activeGame);
      setTimeout(() => {
        console.log("---推荐", item);
        _recommendGameByIDAction(item, 3);
      }, 0);
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../assets/css/page-chess.less");
.mod-poker {
  min-height: 804px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  .mod-banner {
    max-width: 1920px;
    width: 100%;
    height: 465px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 5;
    .slide-wrap {
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .num {
        display: none;
        width: 100%;
        position: absolute;
        bottom: 20px;
        left: 0;
        z-index: 10;
        .current {
          width: 56px;
          opacity: 1;
        }
        span {
          margin: 0 10px;
          background: #00a6ff;
          opacity: 0.3;
          display: inline-block;
          position: relative;
          cursor: pointer;
          -webkit-transition: 0.3s;
          -o-transition: 0.3s;
          transition: 0.3s;
          width: 12px;
          height: 12px;
          border-radius: 6px;
        }
      }
      ul {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        li {
          position: absolute;
          /* left: 50%; */
          top: 0;
          display: none;
          width: 100%;
          height: 100%;
          list-style-type: none;
          width: 100%;
          height: 100%;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .chess-tab {
    width: 1400px !important;
    margin: 30px auto 0 auto;
    .gameList {
      width: 100% !important;
    }
  }
  .casino-menu {
    height: 41px;
    line-height: 41px;
    border: 1px solid #0697cb;
    border-radius: 20px;
    text-align: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    color: #00a6ff;
    padding: 0 15px;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    white-space: nowrap;
    overflow-x: overlay;
    position: relative;
    display: inline-block;
    min-width: 11rem;
    margin-right: 15px;
    width: fit-content;
    float: unset;
  }
  .current {
    background: #00a6ff;
    color: #fff;
  }
  .games-list {
    max-width: 1400px;
    margin-top: 0;
    padding: 0;
    margin: 0 auto;
    .clearfix {
      width: 100%;
      display: inline-block;
      padding: 0;
      margin: 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        float: left;
        width: 18.75%;
        margin: 0 3.1% 1.25%;
        border-radius: 10px;
        background: #fff;
        position: relative;
        overflow: hidden;
        list-style-type: none;
        img {
          width: 100%;
          height: 247px;
          background-position: top center;
          background-size: 100%;
          border-radius: 0 0 15px 15px;
          display: block;
        }
        .game-name {
          width: 100%;
          text-align: center;
          font-size: 20px;
          line-height: 1.2;
          text-align: center;
          color: #323232;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 14px;
            color: #707070;
            display: block;
          }
        }
        .game-rollover {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          a {
            width: 100%;
            height: 100%;
            text-indent: -9999px;
            cursor: pointer;
            display: block;
            color: #fff;
            text-decoration: none;
            outline: none;
          }
        }
      }
    }
  }
  .poker-text {
    max-width: 800px;
    margin: 0 auto;
  }
  .page-text {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    .layout-height {
      height: 300px;
    }
    .layout {
      max-width: 1200px;
      width: 100%;
      overflow: hidden;
      margin: 0 auto;
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #00a6ff;
        line-height: 30px;
      }
      p {
        font-size: 16px;
        word-break: break-word;
        color: #404040;
        line-height: 24px;
        margin: 0 0;
        padding: 0 0 15px 0;
      }
      a {
        color: #00a6ff;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    .read-more {
      width: 241px;
      height: 41px;
      margin: 30px auto;
      box-sizing: border-box;
      border-radius: 8px;
      border: 1px solid #00a6ff;
      line-height: 41px;
      text-align: center;
      color: #00a6ff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>